const apiUrl = 'http://hml.cardoor.com.br';

const endpointCity = 'api/basico/cidades/';
const endpointState = 'api/basico/estados/';
const endpointMetodoConhecimento = 'api/basico/metodos-conhecimentos/';
const endpointSendForm = 'api/pre-cadastros/';

export const BasicApi = {
    async listStates() {
        return await fetch(`${apiUrl}/${endpointState}`)
            .then(res => res.json())
            .then(
                (result) => {
                    return result
                },
                (error) => {
                    console.log(error);
                    return []
                }
            )
    },
    async listCities(idEstado) {
        return await fetch(`${apiUrl}/${endpointCity}?estado=${idEstado}`)
            .then(res => res.json())
            .then(
                (result) => {
                    return result
                },
                (error) => {
                    console.log(error);
                    return []
                }
            )
    },
    async listKnowMethods() {
        return await fetch(`${apiUrl}/${endpointMetodoConhecimento}`)
            .then(res => res.json())
            .then(
                (result) => {
                    return result
                },
                (error) => {
                    console.log(error);
                    return []
                }
            )
    },

    async sendForm(email, telefone, nome, cidade, metodo_conhecimento, outro_metodo) {
        var data = {
            email: email,
            telefone: telefone,
            nome: nome,
            cidade: cidade,
        }
        if (metodo_conhecimento != '') {
            data['metodo_conhecimento'] = metodo_conhecimento
        } else {
            data['outro_metodo_conhecimento'] = outro_metodo    
        }
        return await fetch(`${apiUrl}/${endpointSendForm}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        
    }

}


