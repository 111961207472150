import React from 'react'

export default function Footer() {
    return (
        <footer className="pt-1 pb-5">
            <div className="text-center">
                <img src="/assets/images/cardoor.jpeg" alt="" className="img-fluid" />
            </div>
        </footer>
    )
}
