import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BasicApi } from '../../services/api'
import InputMask from 'react-input-mask'

const validationSchema = Yup.object({
    firstName: Yup.string().required("O nome é obrigatório."),
    lastName: Yup.string().required("Sobrenome é obrigatório."),
    phoneNumber: Yup.string().required("Telefone é obrigatório."),
    email: Yup.string().required("Email é obrigatório."),
    idState: Yup.number().positive("O estado é obrigatório"),
    idCity: Yup.number().positive("A cidade é obrigatória"),
    // idMethod: Yup.number().positive("O método é obrigatório"),
});

export default function FormContent() {
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [outroMetodoConhecimento, setoutroMetodoConhecimento] = useState(false);
    const [serverErrors, setserverErrors] = useState([]);
    const [hasErrorServer, setHasErrorServer] = useState(false);
    const [successSave, setSuccessSave] = useState(false);
    const [metodoConhecimento, setmetodoConhecimento] = useState([]);
    const [idCapital, setIdCapital] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            idState: 0,
            idCity: 0,
            idMethod: 0,
            anotherMethod: ''
        },
        validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        onSubmit: async values => {
            // alert(JSON.stringify(values, null, 2));
            await BasicApi.sendForm(
                values.email,
                values.phoneNumber,
                values.firstName + " " + values.lastName,
                values.idCity,
                values.idMethod,
                values.anotherMethod
            )
                .then(
                    (result) => {
                        if (Object.keys(result).length < 4) {
                            setserverErrors(result);
                            setHasErrorServer(true);
                            setSuccessSave(false);
                        } else {
                            setHasErrorServer(false);
                            setSuccessSave(true);
                            formik.handleReset();
                        }
                    },
                    (error) => {
                        setserverErrors(error);
                    }
                );
        },
    });
    const onChangeEstado = async (idEstado) => {
        if (idEstado != 0){
            var resultCidades = await BasicApi.listCities(idEstado);
            setCidades(resultCidades);
        }
    }

    
    useEffect(async () => {
        var resultMetodoConhecimento = await BasicApi.listKnowMethods();
        var resultEstados = await BasicApi.listStates();
        setEstados(resultEstados);
        setmetodoConhecimento(resultMetodoConhecimento);
    }, [])

    return (
        <section id="content" style={{
            background: '#fff'
        }}>
            <div className="container">
                <div className="row">
                    <h3>
                        Cadastre-se!
                    </h3>
                    <form className="row g-3" onSubmit={formik.handleSubmit}>
                        <div className="col-md-6">
                            <label htmlFor="firstName" className="form-label">Nome <span className="required">*</span></label>
                            <input
                                type="text"
                                placeholder="Nome"
                                className={"form-control " + (formik.errors.firstName ? 'is-invalid' : '')}
                                id="firstName"
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                            />
                            {formik.errors.firstName ?
                                <div className="invalid-feedback">
                                    {formik.errors.firstName}
                                </div>
                                : null}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lastName" className="form-label">Sobrenome  <span className="required">*</span></label>
                            <input
                                type="text"
                                placeholder="Sobrenome"
                                className={"form-control " + (formik.errors.lastName ? 'is-invalid' : '')}
                                id="lastName"
                                onChange={formik.handleChange}
                                value={formik.values.lastName}
                            />
                            {formik.errors.lastName ?
                                <div className="invalid-feedback">
                                    {formik.errors.lastName}
                                </div>
                                : null}
                        </div>
                        <div className="col-12">
                            <label htmlFor="phoneNumber" className="form-label">Insira seu número de telefone  <span className="required">*</span></label>
                            <InputMask
                                type="text"
                                placeholder="Telefone"
                                mask="(99)99999-9999"
                                className={"form-control " + (formik.errors.phoneNumber ? 'is-invalid' : '')}
                                id="phoneNumber"

                                onChange={formik.handleChange}
                                value={formik.values.phoneNumber}
                            />
                            {formik.errors.phoneNumber ?
                                <div className="invalid-feedback">
                                    {formik.errors.phoneNumber}
                                </div>
                                : null}
                        </div>
                        <div className="col-12">
                            <label htmlFor="email" className="form-label">Insira seu endereço de e-mail  <span className="required">*</span></label>
                            <input
                                type="email"
                                placeholder="Email"
                                className={"form-control " + (formik.errors.email ? 'is-invalid' : '')}
                                id="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            {formik.errors.email ?
                                <div className="invalid-feedback">
                                    {formik.errors.email}
                                </div>
                                : null}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="idState" className="form-label">Estado  <span className="required">*</span></label>
                            <select
                                id="idState"
                                onChange={(e) => {
                                    setCidades([])
                                    setIdCapital(false)
                                    formik.handleChange(e);
                                    onChangeEstado(e.target.value)
                                }}
                                value={formik.values.idState}
                                className={"form-select " + (formik.errors.idState ? 'is-invalid' : '')}
                            >
                                <option value="0">Selecione o estado</option>
                                {estados.map(estado => {
                                    return (
                                        <option value={estado.id}>{estado.nome}</option>
                                    )
                                })}
                            </select>
                            {formik.errors.idState ?
                                <div className="invalid-feedback">
                                    {formik.errors.idState}
                                </div>
                                : null}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="idCity" className="form-label">Cidade  <span className="required">*</span></label>
                            <select
                                id="idCity"
                                onChange={formik.handleChange}
                                value={formik.values.idCity}
                                defaultValue={idCapital}
                                className={"form-select " + (formik.errors.idCity ? 'is-invalid' : '')}
                            >
                                <option value="0">Selecione uma cidade</option>
                                {cidades?.map(cidade => {
                                    if (!idCapital && cidade.capital){
                                        setIdCapital(true)
                                        formik.setFieldValue('idCity', cidade.id)
                                    }
                                    
                                    return (
                                        <option value={cidade.id} defaultChecked='checked'>{cidade.nome}</option>
                                    )
                                    
                                })}
                            </select>
                            {formik.errors.idCity ?
                                <div className="invalid-feedback">
                                    {formik.errors.idCity}
                                </div>
                                : null}
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="idMethod" className="form-label">Como você conheceu a CarDoor?  </label>
                            <select
                                id="idMethod"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    if (e.target.value == '') {
                                        setoutroMetodoConhecimento(true)
                                    } else {
                                        setoutroMetodoConhecimento(false)
                                    }
                                }}
                                value={formik.values.idMethod}
                                className={"form-select " + (formik.errors.idMethod ? 'is-invalid' : '')}
                            >
                                <option value="0">Selecione 1 método</option>
                                {metodoConhecimento.map(metodo => {
                                    return (
                                        <option value={metodo.id}>{metodo.nome}</option>
                                    )
                                })}

                                <option value="">Outro</option>
                            </select>
                            {formik.errors.idMethod ?
                                <div className="invalid-feedback">
                                    {formik.errors.idMethod}
                                </div>
                                : null}
                        </div>

                        {
                            outroMetodoConhecimento ?
                                <div className="col-12">
                                    <label htmlFor="anotherMethod" className="form-label">Como você conheceu?</label>
                                    <input
                                        type="text"
                                        placeholder="Amigo, Internet, Em outro carro..."
                                        className={"form-control " + (formik.errors.anotherMethod ? 'is-invalid' : '')}
                                        id="anotherMethod"
                                        onChange={formik.handleChange}
                                        value={formik.values.anotherMethod}
                                    />
                                    {formik.errors.phoneNumber ?
                                        <div className="invalid-feedback">
                                            {formik.errors.anotherMethod}
                                        </div>
                                        : null}
                                </div>
                                : null
                        }

                        {hasErrorServer ?
                            <div class="alert alert-danger" role="alert">
                                {serverErrors["email"] ? serverErrors["email"] : null}
                                {serverErrors["nome"] ? serverErrors["nome"] : null}
                                {serverErrors["telefone"] ? serverErrors["telefone"] : null}
                            </div>
                            :
                            null
                        }
                        {successSave ?
                            <div class="alert alert-success" role="alert">
                                E-mail cadastrado com sucesso!
                            </div>
                            :
                            null
                        }

                        <div className="col-12">
                            <button type="submit" className="btn btn-primary btn-register w-100">
                                <div className="row">
                                    <div className="col-7 text-left">
                                        CADASTRAR
                                    </div>
                                    <div className="col-5 text-right">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            className="bi bi-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </section>
    )
}
