import React from 'react'

export default function Header() {
    return (
        <header id="header-intro" className="position-relative d-flex justify-content-center pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center pt-5">
                            Receba por mês <br />
                            para anunciar no seu carro!
                        </h1>
                        <p className="text-center">Seja o primeiro a ser informado sobre o lançamento da plataforma CarDoor.</p>
                    </div>
                    <div className="car-ad col-6 text-center">
                        <img src="/assets/images/carro_cardoor_02.png" alt="" className="img-fluid" style={{
                            maxWidth: '800px',
                            width: '100%'
                        }} />
                    </div>
                </div>
            </div>
        </header>
    )
}
